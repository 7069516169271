import React, { useState, useEffect } from "react";
import Timer from "../services/timer.js";

export default function PomoTimer() {
  //var timerService = new Timer();

  const [timer, setTimer] = useState(new Timer());
  const [timerValue, setTimerValue] = useState("");
  const [timerToggle, setTimerToggle] = useState("false");

  // Pause Checker:
  function toggleIsPaused() {
    timer.startOrStopTimer();
    setTimerToggle(true);
  }

  document.onkeydown = (e) => {
    if (e.key === "ArrowUp") {
      incTime();
      console.log("up arrow pressed");
    } else if (e.key === "ArrowDown") {
      decTime();
      console.log("down arrow pressed");
    } else if (e.code === "Space" && e.shiftKey) {
      toggleIsPaused();
    }
  };
  // Time Increment:
  const incTime = () => {
    if (timer.minute < 55) {
      timer.minute = timer.minute + 5;
    } else {
      timer.hour = timer.hour + 1;
      timer.minute = timer.minute - 55;
    }
  };
  // Time Decrement:
  // NOT WORKING
  const decTime = () => {
    if (timer.isRunning == false) {
      if (timer.minute >= 5) {
        timer.minute = timer.minute - 5;
      } else if (timer.hour !== 0) {
        timer.hour = timer.hour - 1;
        timer.minute = timer.minute + 55;
      } else {
        timer.minute = timer.minute - timer.minute;
        timer.second = timer.second - timer.second;
      }
    }
  };

  // Checking for 0 second to change it to 00
  let newSecond;
  if (timer.second == 0) {
    newSecond = "0" + timer.second;
  } else {
    newSecond = timer.second;
  }
  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimer(timer);
      setTimerValue(timer.getText());
    }, 100);

    // Clear the interval on unmount
    return () => clearInterval(intervalId);
  }, [timerValue]);
  // Hour Condition:
  document.title = `${timerValue} | Chaidoro`;
  // Render:
  return (
    <div>
      {/* Absolutes */}
      <div>
        <svg
          className="pointer-events-none absolute top-1/2 left-1/2 h-[500px] -translate-x-1/2 -translate-y-1/2"
          width="800"
          height="800"
          viewBox="0 0 473 370"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M347.162 74.3377L420.702 0.798621"
            stroke="#252525"
            stroke-width="1.45"
            stroke-linecap="round"
          />
          <path
            d="M52.299 369.201L125.838 295.662"
            stroke="#252525"
            stroke-width="1.45"
            stroke-linecap="round"
          />
        </svg>
      </div>
      <section
        className=" flex min-h-[275px] min-w-[275px] 
      items-center justify-center"
      >
        <div className="z-20 max-h-96">
          <svg
            onClick={incTime}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            data-slot="icon"
            class="h-6 w-6"
            className="mx-auto max-w-[50px] cursor-pointer"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="m4.5 15.75 7.5-7.5 7.5 7.5"
            />
          </svg>

          <h1
            onClick={toggleIsPaused}
            className="curssor-pointer mx-auto bg-xbg text-center font-poppins text-6xl font-semibold text-xblack transition duration-300 ease-in-out hover:text-xblack"
          >
            {timer.minute}
          </h1>
          {/* Middle Circle */}
          <div
            onClick={toggleIsPaused}
            className="z-30 m-3 mx-auto h-[30px] w-[30px] cursor-pointer rounded-full bg-xblack"
          ></div>
          <h1
            onClick={toggleIsPaused}
            className="mx-auto cursor-pointer bg-xbg text-center font-poppins text-5xl font-thin text-xblack transition duration-300 ease-in-out hover:text-xblack"
          >
            {newSecond}
          </h1>
          <svg
            onClick={decTime}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            data-slot="icon"
            class="h-6 w-6"
            className="z-10 mx-auto max-w-[50px] rotate-180 cursor-pointer"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="m4.5 15.75 7.5-7.5 7.5 7.5"
            />
          </svg>
        </div>
      </section>
    </div>
  );
}
