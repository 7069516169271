import React from "react";
import NavBar from "../components/rightBar.jsx";
import ProfileBar from "../components/profileBar.jsx";
import PomoTimer from "../components/pomotime.jsx";
import TaskBar from "../components/taskBar.jsx";
import Decorations from "../services/decorations.jsx";

export default function MainPage() {
  return (
    <div className="min-h-dvh overflow-clip bg-xbg">
      {/* Decorations: Has styling inside */}
      <Decorations />
      <section className="flex justify-center">
        {/* Main Screen  */}
        <div className="relative min-h-screen py-9 xl:w-[45%] xl:px-3">
          {/* Top Bar */}
          <div className="flex justify-between gap-12 sm:gap-24 md:gap-40">
            {/* Navigation Bar */}
            <div className="pr-3">
              <NavBar />
            </div>
            {/* Profile Bar */}
            <div className="z-0 pl-3">
              <ProfileBar />
            </div>
          </div>
          {/* Clock */}
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform">
            <PomoTimer />
          </div>
          {/* Task Bar */}
          <div className="">
            <TaskBar />
          </div>
        </div>
      </section>
    </div>
  );
}
