// import clockBar from "../img/clockBar.svg";
// import axios from "axios";
import { useState, useEffect } from "react";
import Timer from "../services/timer";
import menuIcon from "../img/vectors/menu.png";

export default function ProfileBar() {
  const [timer, setTimer] = useState(new Timer());
  const [timerValue, setTimerValue] = useState("");

  const [ip, setIp] = useState();
  const getIp = async () => {
    const response = await fetch("https://ipapi.co/json/");
    const data = await response.json();
    setIp(data.ip);
  };

  // Here should be the script for displaying used time per day.
  // Time should reset after 24 hours pass for users timezone.
  // Tasks for reset:
  // Get the user timezone. (ETC: 23:55:10 June 6 2024 (Monday))
  // When user adds tasks give them a date.
  // All the tasks should have a date value. (To be displayed on statistics)
  // Check if the date task added is different than the current user date. (How to implement ? Should client be checked every minute ?)
  // If date is different write display 0 and continue from the beginning.
  // Aaaaa idk idk >~~~<

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimer(timer);
      setTimerValue(timer.getText());
    }, 100);
    return () => clearInterval(intervalId);
  }, []);
  useEffect(() => {
    getIp();
  }, []);
  // Render:
  return (
    <section className=" relative flex flex-col justify-end">
      <div className="flex max-h-20 items-center justify-start rounded-xl border-8 border-secondary bg-secondary shadow-sm shadow-xblack">
        <h1 className="z-10 ml-9 cursor-pointer px-6 text-right font-quicksand text-xs font-extrabold text-xblack md:text-xl">
          ✶ /ᐠﹷ ‸ ﹷ ᐟ\ﾉ ✮⋆˙
        </h1>
      </div>
      <div className="mx-auto flex flex-row rounded-b-xl border border-xblack bg-xblack px-3 font-poppins text-[0.75rem] font-light text-secondary shadow-sm shadow-xblack sm:text-[1rem]">
        <h2 className="mr-6 text-left font-semibold sm:mr-14 2xl:mr-14">
          {ip}
        </h2>
        <img
          className="absolute ml-[5.4rem] w-7 rounded-b-full bg-accent p-1 shadow-sm shadow-xblack sm:ml-[7.5rem] sm:w-11 2xl:ml-[7.5rem]"
          src={menuIcon}
          alt="Menu"
        />
      </div>
      {/* used Time Display
      <div className="flex flex-row border border-xblack rounded-b-xl text-secondary bg-xblack shadow-sm shadow-xblack mx-auto px-9 font-poppins font-light text-[0.6rem]">
        <img className="pr-2 stroke-xblack" src={clockBar} alt="Clock bar" />
        <h2>
          <span className="font-semibold">{25 - timer.minute}</span> hr /
          <span className="font-semibold">{timer.second}</span>Min
        </h2>
      </div> */}
    </section>
  );
}
