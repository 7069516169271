import "./App.css";
import MainPage from "./pages/mainPage.jsx";

function App() {
  return (
    <section className="selection:text-accent overflow-x-hidden">
      <MainPage />
    </section>
  );
}

export default App;
